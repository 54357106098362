<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-4
  v-card-title.d-flex.justify-space-between.align-center
    div {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`, { number: sailorDocument.number }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')") (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    SailorPassportStatementInfo(
      v-if="sailorDocument.behavior.viewInfoBlock"
      :sailorDocument="sailorDocument"
      :comments="comments")

    SailorPassportStatementEditStatus(
      v-else-if="sailorDocument.behavior.viewEditStatusBlock"
      :sailorDocument="sailorDocument")

    SailorPassportStatementUploadFiles(
      v-else-if="sailorDocument.behavior.viewTransferBlock"
      :sailorDocument="sailorDocument")

    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.photo"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="removeFileSailorPassportStatement"
      :editFile="updateFileSailorPassportStatement"
      isShowEdit
    )
</template>

<script>

import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorPassportStatementDocument',
  components: {
    SailorPassportStatementInfo: () => import('@/views/Sailor/SailorPassportStatement/Info.vue'),
    SailorPassportStatementEditStatus: () => import('@/views/Sailor/SailorPassportStatement/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue'),
    SailorPassportStatementUploadFiles: () => import('./UploadFiles.vue')
  },
  data () {
    return {
      type: 'sailorPassportStatement',
      comments: [],
      back,
      checkAccess,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink'
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 2,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('sailorPassportStatement', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus'
        },
        {
          id: 7,
          name: 'mdi-file-upload-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewTransferBlock'),
          checkAccess: () => checkAccess('recordBookStatement', 'transfer', this.sailorDocument) || checkAccess('recordBookStatement', 'add_file'),
          color: '#2c09ed',
          tooltip: 'documentIconControl.addFiles'
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('sailorPassportStatement', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files'
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('sailorPassportStatement', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete'
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.goBack(),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorDocumentByID']),
    ...mapState({
      sailorDocument: state => state.sailor.sailorPassportStatementById
    })
  },
  mounted () {
    this.getData()
    const { viewEditBlock } = this.$route.params
    if (viewEditBlock) viewDetailedComponent(this.sailorDocument, 'viewEditBlock')
  },
  methods: {
    ...mapActions(['getSailorPassportStatementById', 'deleteSailorPassportStatementById',
      'removeFileSailorPassportStatement', 'updateFileSailorPassportStatement']),
    goBack () {
      this.$router.push({ name: 'passports-statements', params: { id: this.$route.params.id } })
    },
    async getData () {
      await this.getSailorPassportStatementById({ ...this.$route.params })
    },
    deleteDocument () {
      deleteConfirmation(this).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.deleteSailorPassportStatementById({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) {
            this.$notification.success('deletedDocument')
            back('passports-statements')
          } else {
            this.$notification.error('cantDeleteDoc')
          }
        }
      })
    }
  }
}

</script>
